<template>
  <div class="timeline-documentation-page">
    <div
      class="hb-primary hb-design-system-border-radius px-4 white--text mb-6"
    >
      <hb-header :divider="false">
        <hb-page-header title="Timeline">
          <template v-slot:description>
            <div>import { HbTimeline, HbTimelineItem, HbTimelineItemAction, HbTimelineItemSubAction } from 'hummingbird-aviary';</div>
          </template>
        </hb-page-header>
      </hb-header>
    </div>
    <hb-global-notification
        v-model="successToggle"
        type="success"
        description="Code copied to clipboard."
    >
    </hb-global-notification>
    <hb-tabs v-model="activeTab">
      <v-tab :ripple="false"> Prototype + API </v-tab>
      <v-tab :ripple="false"> Examples </v-tab>
    </hb-tabs>

    <v-divider></v-divider>
    <div v-if="activeTab === 0" class="pb-4">
      <hb-card class="mt-4 mb-6" title="HbTimeline, HbTimelineItem, HbTimelineItemAction, &amp; HbTimelineItemSubAction Usage + Code">
        <div class="ma-4">
          <v-row class="mb-4">
            <v-col lg="6" md="12" sm="12" xs="12">
              <hb-timeline key="editableExample">
                <!--The key is created with dynamic values for fixing hydration issue while updating slot and its content -->
                <hb-timelineItem
                  v-for="(event, index) in events"
                  :key="`${index}-first-${showFirstColSlot}-second-${showSecondColSlot}-action-${showActionSlot}-add-action-${showAdditionalActionSlot}-expand-${event.expandAction}`"
                  :stage-type="event.stageType"
                  :day-section-title="event.daySection.title"
                  :day-section-description-one="event.daySection.descriptionOne"
                  :day-section-description-two="event.daySection.descriptionTwo"
                  :name-section-sub-title="event.nameSection.subTitle"
                  :name-section-title="event.nameSection.title"
                  :name-section-description-one="event.nameSection.descriptionOne"
                  :name-section-description-two="event.nameSection.descriptionTwo"
                  :name-section-title-font-color="event.nameSection.titleFontColor"
                  :name-section-sub-title-font-color="event.nameSection.subTitleFontColor"
                  :action-label="event.actionLabel"
                  :show-action-toggle="!!event.showActionToggle"
                  :expand-action="event.expandAction"
                  :action-border="!!event.actionBorder"
                  :action-wrapper-class="event.actionWrapperClass"
                  :action-toggle-color="event.actionToggleColor"
                  :action-count="event.actions.length"
                  :today="event.today"
                  :background-color="event.backgroundColor"
                  :day-section-title-font-color="event.daySection.titleFontColor"
                  :description-font-color="event.descriptionFontColor"
                  :audit="event.audit"
                  :audit-verified="event.auditVerified"
                  :audit-verified-by="event.auditVerifiedBy"
                  :audit-verified-on="event.auditVerifiedOn"
                  :audit-verified-allow-undo="event.auditVerifiedAllowUndo"
                  @audit-mark-as-verified-clicked="handleClick('@audit-mark-as-verified-clicked', 'HbTimelineItem')"
                  @audit-undo-clicked="handleClick('@audit-undo-clicked', 'HbTimelineItem')"
                >
                  <template v-if="showMainSlot">
                    <div class="slot-wrap">
                      <h4 class="hb-font-body-medium">Main column slot</h4>
                    </div>
                  </template>
                  <template v-if="showFirstColSlot" #firstColumn>
                    <div class="slot-wrap">
                      <h4 class="hb-font-body-medium">First column slot</h4>
                    </div>
                  </template>
                  <template v-if="showSecondColSlot" #secondColumn>
                    <div class="slot-wrap">
                      <h4 class="hb-font-body-medium">Second column slot</h4>
                    </div>
                  </template>
                  <template v-if="showActionSlot && event.actions && event.actions.length" #action>
                    <span v-for="(action, index) in event.actions" :key="index">
                      <HbTimelineItemAction
                        :type="action.type"
                        :title="action.title"
                        :description="action.description"
                        :link-text="action.link && action.link.text ? action.link.text : ''"
                        :audit-trail="action.auditTrail"
                        :audit-trail-tooltip-name="action.auditTrailTooltipName"
                        :audit-trail-tooltip-time="action.auditTrailTooltipTime"
                        @link-clicked="handleClick('@link-clicked', 'HbTimelineItemAction')"
                      />
                      <HbTimelineItemSubAction
                        v-for="(subAction, index) in action.subActions"
                        :key="index"
                        :type="subAction.type"
                        :icon="subAction.icon"
                        :title="subAction.title"
                        :description="subAction.description"
                        :link-text="subAction.link && subAction.link.text ? subAction.link.text : ''"
                        @link-clicked="handleClick('@link-clicked', 'HbTimelineItemSubAction')"
                      />
                    </span>
                  </template>
                  <template v-if="showAdditionalActionSlot" #additionalActions>
                    <div class="slot-wrap">
                      <h4 class="hb-font-body-medium">Additional Actions slot</h4>
                    </div>
                  </template>
                </hb-timelineItem>
              </hb-timeline>
            </v-col>
            <v-col lg="6" md="12" sm="12" xs="12">
              <hb-card class="mb-2" title="Add New Stage">
                <hb-form label="Stage Type" extra-large :divider="false">
                  <HbSelect
                    v-model="newStageType"
                    :items="stageTypes"
                    item-text="text"
                    item-value="value"
                    :clearable="false"
                    placeholder="Select Type"
                    :divider="true"
                  />
                </hb-form>
                <hb-bottom-action-bar cancel-off>
                  <template v-slot:right-actions>
                    <hb-btn @click="addStage()">Add stage</hb-btn>
                  </template>
                </hb-bottom-action-bar>
              </hb-card>

              <hb-card class="mb-2" title="Slots">
                <hb-form label='"default" <slot>' extra-large>
                  <HbSwitch v-model="showMainSlot" />
                </hb-form>
                <hb-form
                  v-if="!showMainSlot"
                  label='"firstColumn" <slot>'
                  extra-large
                >
                  <HbSwitch v-model="showFirstColSlot" />
                </hb-form>
                <hb-form
                  v-if="!showMainSlot"
                  label='"secondColumn" <slot>'
                  extra-large
                >
                  <HbSwitch v-model="showSecondColSlot" />
                </hb-form>
                <hb-form
                  v-if="!showMainSlot && !showSecondColSlot"
                  extra-large
                  label='"action" <slot>'
                >
                  <HbSwitch v-model="showActionSlot" />
                </hb-form>
                <hb-form
                  v-if="!showMainSlot && !showSecondColSlot"
                  extra-large
                  label='"additionalActions" <slot>'
                >
                  <HbSwitch v-model="showAdditionalActionSlot" />
                </hb-form>
              </hb-card>
              
              <hb-card class="timeline-prototype" title="Update Selected Stage">
                <hb-form label="Select Stage" extra-large>
                  <HbSelect
                    v-model="selectedStage"
                    :items="stageList"
                    placeholder="Select Stage"
                    :clearable="false"
                  />
                </hb-form>
                <hb-form
                  label="background-color"
                  extra-large
                  v-if="hasFieldvalue(events[selectedStage].backgroundColor)"
                >
                  <HbTextField
                    v-model="events[selectedStage].backgroundColor"
                    placeholder="Enter Background Color"
                  />
                </hb-form>
                <template v-if="!showMainSlot">
                  <template v-if="!showFirstColSlot || !showSecondColSlot">
                    <hb-form
                      label="description-font-color"
                      full
                      extra-large
                      v-if="
                        hasFieldvalue(
                          events[selectedStage].descriptionFontColor
                        )
                      "
                    >
                      <HbTextField
                        v-model="
                          events[selectedStage].descriptionFontColor
                        "
                        placeholder="Enter Day Section Description Font Color"
                      />
                    </hb-form>
                    <hb-form label="stage-type" extra-large>
                      <HbSelect
                        v-model="events[selectedStage].stageType"
                        @change="changeStageType(events[selectedStage].stageType, selectedStage)"
                        :items="stageTypes"
                        :clearable="false"
                        item-text="text"
                        item-value="value"
                        placeholder="Select Type"
                      />
                  </hb-form>
                  </template>
                  <template
                    v-if="!showFirstColSlot && events[selectedStage].daySection"
                  >
                  <hb-form 
                    label="today" 
                    extra-large
                    v-if="events[selectedStage].stageType !== 'today'"
                  >
                    <HbSwitch v-model="events[selectedStage].today" />
                  </hb-form>
                    <hb-form
                      label="day-section-title"
                      v-if="
                        hasFieldvalue(events[selectedStage].daySection.title)
                      "
                      extra-large
                    >
                      <HbTextField
                        v-model="events[selectedStage].daySection.title"
                        placeholder="Enter Title"
                      />
                    </hb-form>
                    <hb-form
                      label="day-section-title-font-color"
                      full
                      extra-large
                      v-if="
                        hasFieldvalue(
                          events[selectedStage].daySection.titleFontColor
                        )
                      "
                    >
                      <HbTextField
                        v-model="
                          events[selectedStage].daySection.titleFontColor
                        "
                        placeholder="Enter Day Section Title Font Color"
                      />
                    </hb-form>

                    <hb-form
                      label="day-section-description-one"
                      full
                      extra-large
                      v-if="
                        hasFieldvalue(
                          events[selectedStage].daySection.descriptionOne
                        )
                      "
                    >
                      <HbTextarea
                        v-model="
                          events[selectedStage].daySection.descriptionOne
                        "
                        placeholder="Enter Description One"
                      />
                    </hb-form>
                    <hb-form
                      label="day-section-description-two"
                      full
                      extra-large
                      v-if="
                        hasFieldvalue(
                          events[selectedStage].daySection.descriptionTwo
                        )
                      "
                    >
                      <HbTextarea
                        v-model="
                          events[selectedStage].daySection.descriptionTwo
                        "
                        placeholder="Enter Description Two"
                      />
                    </hb-form>
                  </template>
                  <template
                    v-if="
                      !showSecondColSlot && events[selectedStage].nameSection
                    "
                  >
                    <hb-form
                      extra-large
                      label="name-section-title"
                      v-if="
                        hasFieldvalue(events[selectedStage].nameSection.title)
                      "
                    >
                      <HbTextField
                        v-model="events[selectedStage].nameSection.title"
                        placeholder="Enter Title"
                      />
                    </hb-form>
                    <hb-form
                      extra-large
                      label="name-section-title-font-color"
                      v-if="
                        hasFieldvalue(events[selectedStage].nameSection.titleFontColor)
                      "
                    >
                      <HbTextField
                        v-model="events[selectedStage].nameSection.titleFontColor"
                        placeholder="Enter Name Section Title Font Color"
                      />
                    </hb-form>
                    <hb-form
                      extra-large
                      label="name-section-sub-title"
                      v-if="
                        hasFieldvalue(events[selectedStage].nameSection.subTitle)
                      "
                    >
                      <HbTextField
                        v-model="events[selectedStage].nameSection.subTitle"
                        placeholder="Enter Sub Title"
                      />
                    </hb-form>
                    <hb-form
                      extra-large
                      label="name-section-sub-title-font-color"
                      v-if="
                        hasFieldvalue(events[selectedStage].nameSection.subTitleFontColor)
                      "
                    >
                      <HbTextField
                        v-model="events[selectedStage].nameSection.subTitleFontColor"
                        placeholder="Enter Name Section Sub Title Font Color"
                      />
                    </hb-form>
                    <hb-form
                      label="name-section-description-one"
                      full
                      extra-large
                      v-if="
                        hasFieldvalue(
                          events[selectedStage].nameSection.descriptionOne
                        )
                      "
                    >
                      <HbTextarea
                        v-model="
                          events[selectedStage].nameSection.descriptionOne
                        "
                        placeholder="Enter Description One"
                      />
                    </hb-form>
                    <hb-form
                      label="name-section-description-two"
                      full
                      extra-large
                      v-if="
                        hasFieldvalue(
                          events[selectedStage].nameSection.descriptionTwo
                        )
                      "
                    >
                      <HbTextarea
                        v-model="
                          events[selectedStage].nameSection.descriptionTwo
                        "
                        placeholder="Enter Description Two"
                      />
                    </hb-form>
                  </template>
                  <template v-if="!showSecondColSlot && showActionSlot">
                    <hb-form
                      label="action-label"
                      extra-large
                      v-if="hasFieldvalue(events[selectedStage].actionLabel)"
                    >
                      <HbTextField
                        v-model="events[selectedStage].actionLabel"
                        placeholder="Enter Label"
                      />
                    </hb-form>
                    <!-- <hb-form extra-large label="expand-action">
                      <HbSwitch v-model="events[selectedStage].expandAction" />
                    </hb-form> -->
                    <hb-form label="show-action-toggle" extra-large>
                      <HbSwitch
                        v-model="events[selectedStage].showActionToggle"
                      />
                    </hb-form>
                    <hb-form
                      extra-large
                      label="action-toggle-color"
                      v-if="
                        hasFieldvalue(events[selectedStage].actionToggleColor)
                      "
                    >
                      <hb-radio-group
                        row
                        v-model="events[selectedStage].actionToggleColor"
                      >
                        <hb-radio label="Primary" value="primary"></hb-radio>
                        <hb-radio
                          label="Secondary"
                          value="secondary"
                        ></hb-radio>
                      </hb-radio-group>
                    </hb-form>
                    <hb-form extra-large label="action-border">
                      <HbSwitch v-model="events[selectedStage].actionBorder" />
                    </hb-form>
                    <hb-form extra-large label="action-wrapper-class">
                      <HbTextField
                        v-model="events[selectedStage].actionWrapperClass"
                        placeholder="pa-3"
                      />
                    </hb-form>
                    <hb-form extra-large label="audit">
                      <HbSwitch v-model="events[selectedStage].audit" />
                    </hb-form>
                    <template v-if="audit">
                      <hb-form extra-large label="audit-verified">
                        <HbSwitch v-model="events[selectedStage].auditVerified" />
                      </hb-form>
                      <hb-form extra-large label="audit-verified-by">
                        <HbTextField
                          v-model="events[selectedStage].auditVerifiedBy"
                          placeholder="Enter Audit Verified By"
                        />
                      </hb-form>
                      <hb-form extra-large label="audit-verified-on">
                        <HbTextField
                          v-model="events[selectedStage].auditVerifiedOn"
                          placeholder="Enter Audit Verified On"
                        />
                      </hb-form>
                      <hb-form extra-large label="audit-verified-allow-undo">
                        <HbSwitch v-model="events[selectedStage].auditVerifiedAllowUndo" />
                      </hb-form>
                    </template>
                    <!-- <hb-form
                      label="action-count"
                      extra-large
                      v-if="hasFieldvalue(events[selectedStage].actionCount)"
                    >
                      <HbTextField
                        v-model="events[selectedStage].actionCount"
                        placeholder="Enter Count"
                        type="number"
                      />
                    </hb-form> -->
                  </template>

                </template>
              </hb-card>
            </v-col>
            <v-col cols="6" no-gutters class="mb-1">
              <hb-card color="#E0F5F5">
                <template #title>
                  Code 
                  <hb-tooltip>
                    <template v-slot:item>
                      <hb-icon small @click="copyCode('code')" class="pl-3 copy-icon">mdi-content-copy</hb-icon>
                    </template>
                    <template v-slot:body>
                        Copy
                    </template>
                  </hb-tooltip>
                </template>

                <pre
                  class="px-4"
                  :style="codeExpanded ? 'height:auto' : 'height:320px;'"
                >
                  <div style="position:absolute;top:-4px;right:12px;" class="d-flex align-center hb-pointer" @click="codeExpanded = !codeExpanded">
                      {{ codeExpanded ? 'Collapse Code' : 'Expand Code' }}
                      <hb-icon>mdi-chevron-down</hb-icon>
                  </div>
****** WARNING. THE CODE BELOW IS OLD AND NOT 100% VERIFIED. USE CAUTION WHEN USING THIS CODE ******
****** WARNING. THE CODE BELOW IS OLD AND NOT 100% VERIFIED. USE CAUTION WHEN USING THIS CODE ******
****** WARNING. THE CODE BELOW IS OLD AND NOT 100% VERIFIED. USE CAUTION WHEN USING THIS CODE ******
****** WARNING. THE CODE BELOW IS OLD AND NOT 100% VERIFIED. USE CAUTION WHEN USING THIS CODE ******
****** WARNING. THE CODE BELOW IS OLD AND NOT 100% VERIFIED. USE CAUTION WHEN USING THIS CODE ******
****** WARNING. THE CODE BELOW IS OLD AND NOT 100% VERIFIED. USE CAUTION WHEN USING THIS CODE ******
<div id="code">
&lt;hb-timeline&gt;
  &lt;hb-timelineItem
    v-for=&quot;(event, index) in events&quot;
    :key=&quot;index&quot;
    :background-color=&quot;event.backgroundColor&quot;
    :stage-type=&quot;event.stageType&quot;<template v-if="!showMainSlot"><template v-if="!showFirstColSlot">
    :today=&quot;event.today&quot;
    :day-section-title=&quot;event.daySection.title&quot;
    :day-section-title-font-color=&quot;event.daySection.titleFontColor&quot;
    :day-section-description-one=&quot;event.daySection.descriptionOne&quot;
    :day-section-description-two=&quot;event.daySection.descriptionTwo&quot;</template><template v-if="!showFirstColSlot || !showSecondColSlot">
    :description-font-color=&quot;event.descriptionFontColor&quot;</template><template v-if="!showSecondColSlot">
    :name-section-sub-title=&quot;event.nameSection.subTitle&quot;
    :name-section-title=&quot;event.nameSection.title&quot;
    :name-section-description-one=&quot;event.nameSection.descriptionOne&quot;
    :name-section-description-two=&quot;event.nameSection.descriptionTwo&quot;
    :name-section-title-font-color=&quot;event.nameSection.titleFontColor&quot;
    :name-section-sub-title-font-color=&quot;event.nameSection.subTitleFontColor&quot;<template v-if="showActionSlot">
    :action-label=&quot;event.actionLabel&quot;
    :show-action-toggle=&quot;event.showActionToggle&quot;
    :expand-action="event.expandAction"
    :action-toggle-color=&quot;event.actionToggleColor&quot;
    :action-border=&quot;event.actionBorder&quot;
    :action-count=&quot;event.actions.length&quot;
    :action-wrapper-class=&quot;event.actionWrapperClass&quot;
    :audit="event.audit"
    :audit-verified="event.auditVerified"
    :audit-verified-by="event.auditVerifiedBy"
    :audit-verified-on="event.auditVerifiedOn"
    :audit-verified-allow-undo="event.auditVerifiedAllowUndo"
    @audit-mark-as-verified-clicked="handleClick('@audit-mark-as-verified-clicked', 'HbTimelineItem')"
    @audit-undo-clicked="handleClick('@audit-undo-clicked', 'HbTimelineItem')"</template></template></template>
  &gt;<template v-if="showMainSlot">
    &lt;div class=&quot;slot-wrap&quot;&gt;
      &lt;h4&gt;Main column slot&lt;/h4&gt;
    &lt;/div&gt;
    </template><template v-else><template v-if="showFirstColSlot">
    &lt;template #firstColumn&gt;
      &lt;div class=&quot;slot-wrap&quot;&gt;
        &lt;h4&gt;First column slot&lt;/h4&gt;
      &lt;/div&gt;
    &lt;/template&gt;</template><template v-if="showSecondColSlot">
    &lt;template #secondColumn&gt;
      &lt;div class=&quot;slot-wrap&quot;&gt;
        &lt;h4&gt;Second column slot&lt;/h4&gt;
      &lt;/div&gt;
    &lt;/template&gt;</template><template v-else><template v-if="showActionSlot">
    &lt;template v-if="event.actions && event.actions.length" #action>
      &lt;span v-for="(action, index) in event.actions" :key="index">
        &lt;HbTimelineItemAction
          :type="action.type"
          :title="action.title"
          :description="action.description"
          :link-text="action.link && action.link.text ? action.link.text : ''"
          :audit-trail="action.auditTrail"
          :audit-trail-tooltip-name="action.auditTrailTooltipName"
          :audit-trail-tooltip-time="action.auditTrailTooltipTime"
          @link-clicked="handleClick('@link-clicked', 'HbTimelineItemAction')"
        />
        &lt;HbTimelineItemSubAction
          v-for="(subAction, index) in action.subActions"
          :key="index"
          :type="subAction.type"
          :icon="subAction.icon"
          :title="subAction.title"
          :description="subAction.description"
          :link-text="subAction.link && subAction.link.text ? subAction.link.text : ''"
          @link-clicked="handleClick('@link-clicked', 'HbTimelineItemSubAction')"
        />
      &lt;/span>
    &lt;/template&gt;</template><template v-if="showAdditionalActionSlot">
    &lt;template #additionalActions&gt;
      &lt;div class=&quot;slot-wrap&quot;&gt;
        &lt;h4&gt;Additional Action slot&lt;/h4&gt;
      &lt;/div&gt;
    &lt;/template&gt;</template></template></template>
  &lt;/hb-timelineItem&gt;
&lt;/hb-timeline&gt; </div>
                </pre>
              </hb-card>
            </v-col>

                       <v-col cols="6" no-gutters class="mb-1">
              <hb-card color="#E0F5F5">
                <template #title>
                  Data 
                  <hb-tooltip>
                    <template v-slot:item>
                      <hb-icon small @click="copyCode('data')" class="pl-3 copy-icon">mdi-content-copy</hb-icon>
                    </template>
                    <template v-slot:body>
                        Copy
                    </template>
                  </hb-tooltip>
                </template>
                <pre
                  class="px-4"
                  :style="dataExpanded ? 'height:auto' : 'height:320px;'"
                >
                  <div style="position:absolute;top:-4px;right:12px;" class="d-flex align-center hb-pointer" @click="dataExpanded = !dataExpanded">
                      {{ dataExpanded ? 'Collapse Code' : 'Expand Code' }}
                      <hb-icon>mdi-chevron-down</hb-icon>
                  </div>
<div id="data">let events = [
  <template v-for="(event, index) in eventsObj">{{ event }},
  </template>
  ]</div>
                </pre>
              </hb-card>
            </v-col>
          </v-row>
        </div>
      </hb-card>

      <hb-card title="HbTimelineItem Props" class="my-6 pb-1">
        <hb-data-table :headers="propHeaders" :items="propItems" class="pa-6">
        </hb-data-table>
      </hb-card>

      <hb-card title="HbTimelineItem Slots" class="my-6 pb-1">
        <hb-data-table :headers="slotHeaders" :items="slotItems" class="pa-6">
        </hb-data-table>
      </hb-card>

      <hb-card title="HbTimelineItem Events" class="my-6 pb-1">
        <hb-data-table :headers="eventHeaders" :items="eventItems" class="pa-6">
        </hb-data-table>
      </hb-card>

      <hb-card title="HbTimelineItemAction Props" class="my-6 pb-1">
        <hb-data-table :headers="propHeaders" :items="propActionItems" class="pa-6">
        </hb-data-table>
      </hb-card>

      <hb-card title="HbTimelineItemSubAction Props" class="my-6 pb-1">
        <hb-data-table :headers="propHeaders" :items="propSubActionItems" class="pa-6">
        </hb-data-table>
      </hb-card>

      <hb-card title="HbTimelineItemAction Events" class="my-6 pb-1">
        <hb-data-table :headers="eventHeaders" :items="eventActionAndSubActionItems" class="pa-6">
        </hb-data-table>
      </hb-card>

      <hb-card title="HbTimelineItemSubAction Events" class="my-6 pb-1">
        <hb-data-table :headers="eventHeaders" :items="eventActionAndSubActionItems" class="pa-6">
        </hb-data-table>
      </hb-card>
    </div>
    <div v-if="activeTab === 1" class="pb-4">
      <hb-card class="mt-4 mb-6" title="HbTimeline Without Actions">
        <div class="ma-4">
          <v-row no-gutters class="mb-4">
            <v-col cols="12">
              <hb-timeline key="example1">
                <hb-timelineItem
                  v-for="(event, index) in events2"
                  :key="index"
                  :stage-type="event.stageType"
                  :day-section-title="event.daySection.title"
                  :day-section-description-one="event.daySection.descriptionOne"
                  :day-section-description-two="event.daySection.descriptionTwo"
                  :name-section-title="event.nameSection.title"
                  :name-section-description-one="event.nameSection.descriptionOne"
                  :name-section-description-two="event.nameSection.descriptionTwo"
                >
                </hb-timelineItem>
              </hb-timeline>
            </v-col>
          </v-row>

          <v-row no gutters>
            <v-col cols="12" no-gutters class="mb-1">
              <hb-card color="#E0F5F5">
                <template #title>
                  Code + Data 
                  <hb-tooltip>
                    <template v-slot:item>
                      <hb-icon small @click="copyCode('codeWithoutActions')" class="pl-3 copy-icon">mdi-content-copy</hb-icon>
                    </template>
                    <template v-slot:body>
                        Copy
                    </template>
                  </hb-tooltip>
                </template>
                <pre
                  class="px-4"
                  :style="exampleWithoutActionExpanded ? 'height:auto' : 'height:320px;'"
                >
                <div style="position:absolute;top:-4px;right:12px;" class="d-flex align-center hb-pointer" @click="exampleWithoutActionExpanded = !exampleWithoutActionExpanded">
                    {{ exampleWithoutActionExpanded ? 'Collapse Code' : 'Expand Code' }}
                    <hb-icon>mdi-chevron-down</hb-icon>
                </div>
<div id="codeWithoutActions">&lt;hb-timeline>
  &lt;hb-timelineItem
    v-for="(event, index) in events2"
    :key="index"
    :stage-type="event.stageType"
    :day-section-title="event.daySection.title"
    :day-section-description-one="event.daySection.descriptionOne"
    :day-section-description-two="event.daySection.descriptionTwo"
    :name-section-title="event.nameSection.title"
    :name-section-description-one="event.nameSection.descriptionOne"
    :name-section-description-two="event.nameSection.descriptionTwo"
  >
  &lt;/hb-timelineItem>
&lt;/hb-timeline></div>
              </pre>
              </hb-card>
            </v-col>
          </v-row>
        </div>
      </hb-card>

      <hb-card
        class="mt-4 mb-6"
        title="HbTimeline With Actions and Audit Functionality"
      >
        <div class="ma-4">
          <v-row no-gutters class="mb-4">
            <v-col cols="12">
              <hb-timeline key="example2">
                <hb-timelineItem
                  v-for="(event, index) in events3"
                  :key="index"
                  :stage-type="event.stageType"
                  :day-section-title="event.daySection.title"
                  :day-section-description-one="event.daySection.descriptionOne"
                  :day-section-description-two="event.daySection.descriptionTwo"
                  :name-section-title="event.nameSection.title"
                  :name-section-description-one="event.nameSection.descriptionOne"
                  :name-section-description-two="event.nameSection.descriptionTwo"
                  :action-label="event.actionLabel"
                  expand-action
                  show-action-toggle
                  :action-wrapper-class="'pa-3'"
                  :action-toggle-color="event.actionToggleColor"
                  :action-count="event.actions ? event.actions.length : 0"
                  :today="event.today"
                  audit
                  :audit-verified="index < 3 || index === 5"
                  audit-verified-by="Severus Snape"
                  audit-verified-on="Jun 28, 2023 @12:00pm"
                  @audit-mark-as-verified-clicked="handleClick('@audit-mark-as-verified-clicked', 'HbTimelineItem')"
                  @audit-undo-clicked="handleClick('@audit-undo-clicked', 'HbTimelineItem')"
                >
                  <template #action v-if="event.actions && event.actions.length">
                    <HbTimelineItemAction
                      v-for="(action, index) in event.actions"
                      :key="index"
                      :type="action.type"
                      :title="action.title"
                      :description="action.description"
                      :link-text="action.link && action.link.text ? action.link.text : ''"
                      :audit-trail="action.auditTrail"
                      :audit-trail-tooltip-name="action.auditTrailTooltipName"
                      :audit-trail-tooltip-time="action.auditTrailTooltipTime"
                      @link-clicked="handleClick('@link-clicked', 'HbTimelineItemAction')"
                    />
                  </template>
                </hb-timelineItem>
              </hb-timeline>
            </v-col>
          </v-row>

          <v-row no gutters>
            <v-col cols="12" no-gutters class="mb-1">
              <hb-card color="#E0F5F5">
                <template #title>
                  Code + Data 
                  <hb-tooltip>
                    <template v-slot:item>
                      <hb-icon small @click="copyCode('codeWithActions')" class="pl-3 copy-icon">mdi-content-copy</hb-icon>
                    </template>
                    <template v-slot:body>
                        Copy
                    </template>
                  </hb-tooltip>
                </template>
                <pre
                  class="px-4"
                  :style="exampleWithActionExpanded ? 'height:auto' : 'height:320px;'"
                >
                <div style="position:absolute;top:-4px;right:12px;" class="d-flex align-center hb-pointer" @click="exampleWithActionExpanded = !exampleWithActionExpanded">
                    {{ exampleWithActionExpanded ? 'Collapse Code' : 'Expand Code' }}
                    <hb-icon>mdi-chevron-down</hb-icon>
                </div>
<div id="codeWithActions">&lt;hb-timeline>
  &lt;hb-timelineItem
    v-for="(event, index) in events3"
    :key="index"
    :stage-type="event.stageType"
    :day-section-title="event.daySection.title"
    :day-section-description-one="event.daySection.descriptionOne"
    :day-section-description-two="event.daySection.descriptionTwo"
    :name-section-title="event.nameSection.title"
    :name-section-description-one="event.nameSection.descriptionOne"
    :name-section-description-two="event.nameSection.descriptionTwo"
    :action-label="event.actionLabel"
    expand-action
    show-action-toggle
    :action-wrapper-class="'pa-3'"
    :action-toggle-color="event.actionToggleColor"
    :action-count="event.actions ? event.actions.length : 0"
    :today="event.today"
    audit
    :audit-verified="index &lt; 3 || index === 5"
    audit-verified-by="Severus Snape"
    audit-verified-on="Jun 28, 2023 @12:00pm"
    @audit-mark-as-verified-clicked="handleClick('@audit-mark-as-verified-clicked', 'HbTimelineItem')"
    @audit-undo-clicked="handleClick('@audit-undo-clicked', 'HbTimelineItem')"
  >
    &lt;template #action v-if="event.actions && event.actions.length">
      &lt;HbTimelineItemAction
        v-for="(action, index) in event.actions"
        :key="index"
        :type="action.type"
        :title="action.title"
        :description="action.description"
        :link-text="action.link && action.link.text ? action.link.text : ''"
        :audit-trail="action.auditTrail"
        :audit-trail-tooltip-name="action.auditTrailTooltipName"
        :audit-trail-tooltip-time="action.auditTrailTooltipTime"
        @link-clicked="handleClick('@link-clicked', 'HbTimelineItemAction')"
      />
    &lt;/template>
  &lt;/hb-timelineItem>
&lt;/hb-timeline></div>
              </pre>
              </hb-card>
            </v-col>
          </v-row>
        </div>
      </hb-card>

      <hb-card
        class="mt-4 mb-6"
        title="HbTimeline With Default Stage Types and Editable Functionality Example (usually only use this for settings pages like where a user can set up a timeline template)"
      >
        <div class="ma-4">
          <v-row no-gutters class="mb-4">
            <v-col cols="12">
              <HbTimeline key="example1">
                <HbTimelineItem
                  v-for="(event, index) in stagesEditable"
                  :key="index"
                  editable
                  stage-type="default"
                  :day-section-title="event.daySection.title"
                  :day-section-description-one="event.daySection.descriptionOne"
                  :day-section-description-two="event.daySection.descriptionTwo"
                  :name-section-title="event.nameSection.title"
                  :name-section-description-one="event.nameSection.descriptionOne"
                  :name-section-description-two="event.nameSection.descriptionTwo"
                  :action-label="event.actionLabel"
                  :expand-action="true"
                  :show-action-toggle="true"
                  :action-border="true"
                  :action-wrapper-class="'pa-3'"
                  :action-toggle-color="event.actionToggleColor"
                  :action-count="event.actions ? event.actions.length : 0"
                  @edit-clicked="handleClick('@edit-clicked', 'HbTimelineItem')"
                  @delete-clicked="handleClick('@delete-clicked', 'HbTimelineItem')"
                >
                  <template #action v-if="event.actions && event.actions.length">
                    <HbTimelineItemAction
                      v-for="(action, index) in event.actions"
                      :key="index"
                      :type="action.type"
                      :title="action.title"
                      :description="action.description"
                      :link-text="action.link && action.link.text ? action.link.text : ''"
                      @link-clicked="handleClick('@link-clicked', 'HbTimelineItemAction')"
                    />
                  </template>
                </HbTimelineItem>
              </HbTimeline>
            </v-col>
          </v-row>

          <v-row no gutters>
            <v-col cols="12" no-gutters class="mb-1">
              <hb-card color="#E0F5F5">
                <template #title>
                  Code + Data 
                  <hb-tooltip>
                    <template v-slot:item>
                      <hb-icon small @click="copyCode('codeWithEditable')" class="pl-3 copy-icon">mdi-content-copy</hb-icon>
                    </template>
                    <template v-slot:body>
                        Copy
                    </template>
                  </hb-tooltip>
                </template>
                <pre
                  class="px-4"
                  :style="exampleWithEditableExpanded ? 'height:auto' : 'height:320px;'"
                >
                <div style="position:absolute;top:-4px;right:12px;" class="d-flex align-center hb-pointer" @click="exampleWithEditableExpanded = !exampleWithEditableExpanded">
                    {{ exampleWithEditableExpanded ? 'Collapse Code' : 'Expand Code' }}
                    <hb-icon>mdi-chevron-down</hb-icon>
                </div>
<div id="codeWithEditable">
&lt;HbTimeline&gt;
  &lt;HbTimelineItem
    v-for="(event, index) in stagesEditable"
    :key="index"
    editable
    stage-type="default"
    :day-section-title="event.daySection.title"
    :day-section-description-one="event.daySection.descriptionOne"
    :day-section-description-two="event.daySection.descriptionTwo"
    :name-section-title="event.nameSection.title"
    :name-section-description-one="event.nameSection.descriptionOne"
    :name-section-description-two="event.nameSection.descriptionTwo"
    :action-label="event.actionLabel"
    :expand-action="true"
    :show-action-toggle="true"
    :action-border="true"
    :action-wrapper-class="'pa-3'"
    :action-toggle-color="event.actionToggleColor"
    :action-count="event.actions ? event.actions.length : 0"
    @edit-clicked="handleClick('@edit-clicked', 'HbTimelineItem')"
    @delete-clicked="handleClick('@delete-clicked', 'HbTimelineItem')"
  >
    &lt;HbTimelineItemAction
      v-for="(action, index) in event.actions"
      :key="index"
      :type="action.type"
      :title="action.title"
      :description="action.description"
      :link-text="action.link && action.link.text ? action.link.text : ''"
      @link-clicked="handleClick('@link-clicked', 'HbTimelineItemAction')"
    />
  &lt;/HbTimelineItem>
&lt;/HbTimeline&gt;</div>
              </pre>
              </hb-card>
            </v-col>
          </v-row>
        </div>
      </hb-card>
    </div>

    <hb-global-notification
      v-model="successError"
      type="success"
      :list="successErrorList"
      :description="successErrorDescription"
    >
    </hb-global-notification>
  </div>
</template>
<script type="text/babel">

export default {
  name: "DesignSystemTimelines",
  data: function () {
    return {
      successError: false,
      successErrorDescription: '',
      successErrorList: [],
      activeTab: 0,
      newStageType: null,
      selectedStage: 0,
      showFirstColSlot: false,
      showSecondColSlot: false,
      showMainSlot: false,
      showActionSlot: true,
      showAdditionalActionSlot: false,
      codeExpanded: false,
      dataExpanded: false,
      exampleWithoutActionExpanded: false,
      exampleWithActionExpanded: false,
      exampleWithEditableExpanded: false,
      successToggle: false,
      past: {
        stageType: "past",
        daySection: {
          title: "Day 1",
          descriptionOne: "Month 20",
          descriptionTwo: "Jun 20, 2022",
          titleFontColor: "",
        },
        nameSection: {
          title: "Gate Lockout and Late Fee",
          subTitle: "",
          descriptionOne:
            "Deny Access to the Tenant and Charge the legal Late Fee",
          descriptionTwo: "line 2 description",
          titleFontColor: "",
          subTitleFontColor: ""
        },
        expandAction: true,
        showActionToggle: true,
        actionBorder: true,
        iconColor: "#02AD0F",
        lineColor: "#00848E",
        today: false,
        actionWrapperClass: "pa-3",
        // actionCount: 1,
        backgroundColor: '#F9FAFB',
        customIcon: 'mdi-check-circle',
        actionLabel: 'Actions',
        actionToggleColor: 'primary',
        descriptionFontColor: "",
        actions: [
          {
            type: "success",
            title: "Change Tenant Status",
            description: "Active Lien",
            auditTrail: true,
            auditTrailTooltipName: 'Severus Snape',
            auditTrailTooltipTime: 'Sept 20, 2023 @ 4:30pm',
            subActions: [
              {
                type: "success",
                icon: "mdi-email-check-outline",
                title: "Certified Mail",
                description: "Sent - Aug 01, 2022",
              },
            ],
          },
        ],
        audit: false,
        auditVerified: false,
        auditVerifiedBy: 'Severus Snape',
        auditVerifiedOn: 'Jun 28, 2023 @12:00pm',
        auditVerifiedAllowUndo: true,
      },
      current: {
        stageType: "current",
        daySection: {
          title: "Day 13",
          descriptionOne: "Month 20",
          descriptionTwo: "Jun 23, 2022",
          titleFontColor: "",
        },
        nameSection: {
          title: "Add Lien Process Fee",
          subTitle: "",
          descriptionOne: "Preparing for Auction",
          descriptionTwo: "line 2 description",
          titleFontColor: "",
          subTitleFontColor: ""
        },
        expandAction: true,
        showActionToggle: true,
        iconColor: "#00848E",
        lineColor: "#DFE3E8",
        today: false,
        actionBorder: true,
        actionWrapperClass: "pa-3",
        // actionCount: 2,
        backgroundColor: "#E0F5F5",
        customIcon: 'mdi-record-circle',
        actionLabel: 'Actions',
        actionToggleColor: 'primary',
        descriptionFontColor: "",
        actions: [
          {
            title: "Schedule Auction",
            description: "Active Lien",
          },
          {
            title: "Task",
            description: "Task to tow vehicles and boats",
          },
        ],
        audit: false,
        auditVerified: false,
        auditVerifiedBy: 'Severus Snape',
        auditVerifiedOn: 'Jun 28, 2023 @12:00pm',
        auditVerifiedAllowUndo: true,
      },
      future: {
        stageType: "future",
        daySection: {
          title: "Day 16",
          descriptionOne: "Month 20",
          descriptionTwo: "Jun 26, 2022",
          titleFontColor: "",
        },
        nameSection: {
          title: "Gate Lockout and Late Fee",
          subTitle: "",
          descriptionOne:
            "Deny Access to the Tenant and Charge the legal Late Fee Deny Access to the Tenant and Charge the legal Late Fee Deny Access to the Tenant and Charge the legal Late Fee",
          descriptionTwo: "line 2 description",
          titleFontColor: "",
          subTitleFontColor: ""
        },
        expandAction: true,
        showActionToggle: true,
        actionBorder: true,
        iconColor: "#DFE3E8",
        lineColor: "#DFE3E8",
        today: false,
        actionWrapperClass: "pa-3",
        // actionCount: 0,
        backgroundColor: "#FFFFFF",
        customIcon: 'mdi-circle-outline',
        actionLabel: 'Actions',
        actionToggleColor: 'primary',
        descriptionFontColor: "",
        actions: [
          {
            title: "Schedule Auction",
          },
        ],
        audit: false,
        auditVerified: false,
        auditVerifiedBy: 'Severus Snape',
        auditVerifiedOn: 'Jun 28, 2023 @12:00pm',
        auditVerifiedAllowUndo: true,
      },
      error: {
        stageType: "error",
        daySection: {
          title: "Day 5",
          descriptionOne: "Month 20",
          descriptionTwo: "Jun 21, 2022",
          titleFontColor: "",
        },
        nameSection: {
          title: "Inventory Property / Cut Lock",
          subTitle: "",
          descriptionOne: "Create a task for cut lock and inventory",
          descriptionTwo: "line 2 description",
          titleFontColor: "",
          subTitleFontColor: ""
        },
        expandAction: true,
        showActionToggle: true,
        today: false,
        actionBorder: true,
        iconColor: "#FB4C4C",
        lineColor: "#00848E",
        actionWrapperClass: "pa-3",
        // actionCount: 1,
        backgroundColor: "#F9FAFB",
        customIcon: 'mdi-minus-circle',
        actionLabel: 'Actions',
        actionToggleColor: 'primary',
        descriptionFontColor: "",
        actions: [
          {
            type: "error",
            title: "Generate Document",
            description: "Active Lien",
            link: {
              text: "View Document",
              url: "https://sandbox.tenant-platform.com/design-system",
            },
            auditTrail: true,
            auditTrailTooltipName: 'Severus Snape',
            auditTrailTooltipTime: 'Sept 20, 2023 @ 4:30pm',
            subActions: [
              {
                type: "success",
                icon: "mdi-email-check-outline",
                title: "Certified Mail",
                description: "Sent - Aug 01, 2022",
              },
              {
                type: "error",
                icon: "mdi-custom-delivery-error",
                title: "Certified Mail",
                description: "Sent Aug 01, 2022 @ 00:00am. You tried to send to recipient(s) that have been marked as inactive. Found inactive addresses: firm@pelusolaw.net. Inactive recipients are ones that have generated a hard bounce, a spam complaint, or a manual suppression.",
              },
            ],
          },
        ],
        audit: false,
        auditVerified: false,
        auditVerifiedBy: 'Severus Snape',
        auditVerifiedOn: 'Jun 28, 2023 @12:00pm',
        auditVerifiedAllowUndo: true,
      },
      today: {
        stageType: "today",
        daySection: {
          title: "",
          descriptionOne: "",
          descriptionTwo: "Jun 22, 2022",
          titleFontColor: "",
        },
        nameSection: {
          title: "",
          subTitle: "",
          descriptionOne: "",
          descriptionTwo: "",
          titleFontColor: "",
          subTitleFontColor: ""
        },
        expandAction: true,
        showActionToggle: true,
        today: true,
        actionBorder: true,
        iconColor: "#02AD0F",
        lineColor: "#00848E",
        actionWrapperClass: "pa-3",
        // actionCount: 1,
        backgroundColor: '#FFFFFF',
        customIcon: 'mdi-check-circle',
        actionLabel: 'Actions',
        actionToggleColor: 'primary',
        descriptionFontColor: "",
        actions: [],
      },
      default: {
        stageType: "default",
        daySection: {
          title: "Day 18",
          descriptionOne: "Month 26",
          descriptionTwo: "Jan 1, 2023",
          titleFontColor: "",
        },
        nameSection: {
          title: "Default Example for Hummingbird Settings Pages",
          subTitle: "",
          descriptionOne:
            "Set up Template",
          descriptionTwo: "line 2 description",
          titleFontColor: "",
          subTitleFontColor: ""
        },
        expandAction: true,
        showActionToggle: true,
        actionBorder: true,
        iconColor: "#DFE3E8",
        lineColor: "#DFE3E8",
        today: false,
        actionWrapperClass: "pa-3",
        // actionCount: 1,
        backgroundColor: "#FFFFFF",
        customIcon: 'mdi-circle-outline',
        actionLabel: 'Actions',
        actionToggleColor: 'primary',
        descriptionFontColor: "",
        actions: [
          {
            title: "Schedule Auction",
            subActions: [
              {
                icon: "mdi-account",
                title: "Manager Needs to Finish This",
                link: {
                  text: "Verify Checklist",
                  url: "https://sandbox.tenant-platform.com/design-system",
                },
              },
              {
                icon: "mdi-paperclip",
                title: "Do the Paperwork",
                description: "Send to customer.",
              },
            ],
          }
        ],
        audit: false,
        auditVerified: false,
        auditVerifiedBy: 'Severus Snape',
        auditVerifiedOn: 'Jun 28, 2023 @12:00pm',
        auditVerifiedAllowUndo: true,
      },
      events: [],
      stageTypes: [
        { text: "Current", value: "current" },
        { text: "Default", value: "default" },
        { text: "Error", value: "error" },
        { text: "Future", value: "future" },
        { text: "Past", value: "past" },
        { text: "Today", value: "today" },
      ],
      propHeaders: [
        { text: "Name", value: "name", width: "140" },
        { text: "Type", value: "type" },
        { text: "Default", value: "default", width: "110" },
        { text: "Description", value: "description" },
      ],
      slotHeaders: [
        { text: "Name", value: "name" },
        { text: "Description", value: "description" },
      ],
      eventHeaders: [
        { text: "Name", value: "name" },
        { text: "Description", value: "description" },
      ],
      propItems: [
        {
          name: "action-border",
          type: "boolean",
          default: "true",
          description:
            "If set to true, a border will be displayed around the action slot content.",
        },
        {
          name: "action-count",
          type: "number",
          default: "undefined",
          description:
            "Pass the count of actions into this prop. This will be displayed to the right of the Show/Hide toggle for action slot content.",
        },
        {
          name: "action-label",
          type: "string",
          default: "Actions",
          description:
            "Pass the text to display after show/hide text for toggle the action slot content.",
        },
        {
          name: "action-toggle-color",
          type: "string",
          default: "primary",
          description:
            'Sets the color of the link. The options are "primary" or "secondary" or do not set if you want primary color as that is the default visual output.',
        },
        {
          name: "action-wrapper-class",
          type: "string or Array",
          default: "pa-3",
          description:
            "Pass a string/array that specifies the class(es) for the container that holds the actions. For passing multiple classes in single string, use space in between",
        },
        {
          name: "background-color",
          type: "string",
          default: "undefined",
          description:
            "Sets the color of the background. Pass this prop to override the default background color of the stage.",
        },
        {
          name: "custom-icon",
          type: "string",
          default: "undefined",
          description:
            "You should usually not touch this prop. Pass the icon to be displayed for the stage into this prop.",
        },
        {
          name: "day-section-description-one",
          type: "string",
          default: "undefined",
          description:
            "Pass the description text to be displayed in day section into this prop.",
        },
        {
          name: "day-section-description-two",
          type: "string",
          default: "undefined",
          description:
            "Pass the description text to be displayed in day section into this prop.",
        },
        {
          name: "day-section-title",
          type: "string",
          default: "undefined",
          description:
            "Pass the title to be displayed in day section into this prop.",
        },
        {
          name: "day-section-title-font-color",
          type: "string",
          default: "undefined",
          description:
            "Sets the font color of the title in day section. Pass this prop to override the default font color of the day section title.",
        },
        {
          name: "description-font-color",
          type: "string",
          default: "undefined",
          description:
            "Sets the font color of the descriptions. Pass this prop to override the default font color of the descriptions.",
        },
        {
          name: "expand-action",
          type: "boolean",
          default: "true",
          description:
            "If set to true, initially the action slot content will be displayed. If set to false, initially the action slot components will be hidden",
        },
        {
          name: "filled-icon",
          type: "boolean",
          default: "false",
          description:
            "You should not usually touch this prop. If set to true, the icon for the stage will be a filled one instead of an outlined icon. This prop is applied only for default icons",
        },
        {
          name: "icon-color",
          type: "string",
          default: "undefined",
          description:
            "Sets the color of the icon. Use this prop to override the default color which is predefined for each stage",
        },
        {
          name: "line-color",
          type: "string",
          default: "undefined",
          description:
            "Sets the color of the line between stage icons. Use this prop to override the default color which is predefined for each stage",
        },
        {
          name: "name-section-description-one",
          type: "string",
          default: "undefined",
          description:
            "Pass the description text to be displayed in name section into this prop.",
        },
        {
          name: "name-section-description-two",
          type: "string",
          default: "undefined",
          description:
            "Pass the description text to be displayed in name section into this prop.",
        },
        {
          name: "name-section-title",
          type: "string",
          default: "undefined",
          description:
            "Pass the title to be displayed in name section into this prop.",
        },
        {
          name: "name-section-title-font-color",
          type: "string",
          default: "undefined",
          description:
            "Sets the font color of the title in name section. Pass this prop to override the default font color of the name section title.",
        },
        {
          name: "show-action-toggle",
          type: "boolean",
          default: "true",
          description:
            "If set to true, the link to toggle the action slot content will be displayed. If set to false, initially the action slot content will be displayed without the toggle",
        },
        {
          name: "stage-type",
          type: "string",
          default: "default",
          description:
            'Set this prop appropriately to display the proper visual styling for the stage. The available options are "current", "default", "future", "error", and "past".',
        },
        {
          name: "today",
          type: "boolean",
          default: "false",
          description:
            "If set to true, the text 'Today' will be displayed above the day section title.",
        },
        {
          name: "audit",
          type: "boolean",
          default: "false",
          description:
            "If set to true, the auditing functionality will be turned on.",
        },
        {
          name: "audit-verified",
          type: "boolean",
          default: "false",
          description:
            "If set to true, the audit verified information will be shown instead of the default 'Mark as Verified' audit button. The 'audit' prop must be set to true for this information to show.",
        },
        {
          name: "audit-verified-by",
          type: "string",
          default: "undefined",
          description:
            "Set the audit verified by information to be shown when 'audit-verfied' prop is set to true. The 'audit' prop must also be set to true for this information to show.",
        },
        {
          name: "audit-verified-on",
          type: "string",
          default: "undefined",
          description:
            "Set the audit verified on information (use proper date + time display format) to be shown when 'audit-verfied' prop is set to true. The 'audit' prop must also be set to true for this information to show.",
        },
        {
          name: "audit-verified-allow-undo",
          type: "boolean",
          default: "true",
          description:
            "If set to false, the 'Undo' audit verified link will be turned on when 'audit-verified' prop is set to true. The 'audit' prop must also be set to true for this to have any effect.",
        },
        {
          name: "editable",
          type: "boolean",
          default: "false",
          description:
            "If set to true, this will turn on the hover over meatball menu and background color change. If both 'turn-off-edit' and 'turn-off-delete' are set to true, then these hover over behaviors will be turned off.",
        },
        {
          name: "turn-off-edit",
          type: "boolean",
          default: "false",
          description:
            "If set to true, this will turn off the edit option in the meatball menu.",
        },
        {
          name: "turn-off-delete",
          type: "boolean",
          default: "false",
          description:
            "If set to true, this will turn off the delete option in the meatball menu.",
        },
        {
          name: "disable-edit",
          type: "boolean",
          default: "false",
          description:
            "If set to true, this will disable the edit option in the meatball menu.",
        },
        {
          name: "disable-delete",
          type: "boolean",
          default: "false",
          description:
            "If set to true, this will disable the delete option in the meatball menu.",
        },
      ],
      slotItems: [
        {
          name: "default",
          description:
            "This slot is where you can place the content of entire stage if needed. Please be careful to style and space the content nicely if using this slot.",
        },
        {
          name: "firstColumn",
          description:
            "This slot is where you can place code to manually override the first column in timeline component. Please be careful to style and space the content nicely if using this slot.",
        },
        {
          name: "secondColumn",
          description:
            "This slot is where you can place code to manually override the second column in timeline component. Please be careful to style and space the content nicely if using this slot.",
        },
        {
          name: "action",
          description:
            "This slot is where you can place code for the action area which can be toggled.",
        },
        {
          name: "additionalActions",
          description:
            "This slot is where you can place code to be displayed below the action slot in timeline component. Please be careful to style and space the content nicely if using this slot.",
        },
      ],
      eventItems: [
        {
          name: "@audit-mark-as-verified-clicked",
          description:
            "This event is fired when 'audit' prop is set to true and the 'Mark as Verified' button is clicked.",
        },
        {
          name: "@audit-undo-clicked",
          description:
            "This event is fired when 'audit' prop and 'audit-verified' prop is set to true and the 'Undo' audit link is clicked.",
        },
        {
          name: "@edit-clicked",
          description:
            "This event is fired when 'editable' prop is set to true and the 'Edit' option is clicked in the meatball dropdown menu.",
        },
        {
          name: "@delete-clicked",
          description:
            "This event is fired when 'editable' prop is set to true and the 'Delete' option is clicked in the meatball dropdown menu.",
        },
      ],
      eventActionAndSubActionItems: [
        {
          name: "@link-clicked",
          description:
            "This event is fired when the optional link is clicked.",
        },
      ],
      propActionItems: [
        {
          name: "type",
          type: "string",
          default: "undefined",
          description:
            "Sets the type of action. Possible options are 'success' or 'error' to show the appropriate check and minus icons. To show the black dot instead of the success or error icons, just leave this prop empty or set the prop to something like 'default'.",
        },
        {
          name: "title",
          type: "string",
          default: "undefined",
          description:
            "Sets the title text of the action.",
        },
        {
          name: "description",
          type: "string",
          default: "undefined",
          description:
            "Sets the description text of the action.",
        },
        {
          name: "link-text",
          type: "string",
          default: "undefined",
          description:
            "Turns on the optional link and sets the link text of the action.",
        },
        {
          name: "link-url",
          type: "string",
          default: "undefined",
          description:
            "Optional. sets the url link if available for the link. You can alternatively leave this empty and use the '@link-clicked' event to send the user to an internal vue page/route.',"
        },
        {
          name: "audit-trail",
          type: "boolean",
          default: "false",
          description:
            "If set to true, turns on the audit trail icon.',"
        },
        {
          name: "audit-trail-tooltip-header",
          type: "string",
          default: "Last Updated:",
          description:
            "You should usually leave this prop alone. Sets the audit trial icon tooltip header text.',"
        },
        {
          name: "audit-trail-tooltip-name",
          type: "string",
          default: "undefined:",
          description:
            "Sets the audit trail icon tooltip name.',"
        },
        {
          name: "audit-trail-tooltip-time",
          type: "string",
          default: "undefined:",
          description:
            "Sets the audit trail icon tooltip time/date (use proper date + time display format).',"
        },
      ],
      propSubActionItems: [
        {
          name: "type",
          type: "string",
          default: "undefined",
          description:
            "Sets the type of sub action. Possible options are 'success' or 'error' to set/show the appropriate icon and font color. Or leave empty to use default icon and font styling.",
        },
        {
          name: "icon",
          type: "string",
          default: "undefined",
          description:
            "Sets an optional mdi icon. Use 'mdi-xxx' code formatting or other valid mdi code format.",
        },
        {
          name: "iconColor",
          type: "string",
          default: "undefined",
          description:
            "You usually should not touch this prop. Overrides the default icon color set by the 'type' prop.",
        },
        {
          name: "title",
          type: "string",
          default: "undefined",
          description:
            "Sets the title text of the sub action.",
        },
        {
          name: "titleColor",
          type: "string",
          default: "undefined",
          description:
            "You usually should not touch this prop. Overrides the default title text color set by the 'type' prop. Only options are 'success' or 'error'.",
        },
        {
          name: "description",
          type: "string",
          default: "undefined",
          description:
            "Sets the description text of the sub action.",
        },
        {
          name: "descriptionColor",
          type: "string",
          default: "undefined",
          description:
            "You usually should not touch this prop. Overrides the default description text color set by the 'type' prop. Only options are 'error' or 'night' (for black).",
        },
        {
          name: "link-text",
          type: "string",
          default: "undefined",
          description:
            "Turns on the optional link and sets the link text of the action.",
        },
        {
          name: "link-url",
          type: "string",
          default: "undefined",
          description:
            "Optional. sets the url link if available for the link. You can alternatively leave this empty and use the '@link-clicked' event to send the user to an internal vue page/route.',"
        },
        {
          name: "icon-verical-adjustment",
          type: "string",
          default: "-1.5px",
          description:
            "If the icon used is misaligned. You can set a negative or positive pixel or percentage or other adjustment here to fix it.',"
        },
      ],
      events3: [
        {
          stageType: "past",
          daySection: {
            title: "Day 1",
            descriptionOne: "Jun 20, 2022",
          },
          nameSection: {
            title: "Tenant Rent Assessment",
            descriptionOne: "This is the day the tenant's rent is due",
          },
        },
        {
          stageType: "past",
          daySection: {
            title: "Day 2",
            descriptionOne: "Jun 21, 2022",
          },
          nameSection: {
            title: "Tenant becomes Delinquent",
            descriptionOne: "Description",
            descriptionTwo: "line 2 description",
          },
          expandAction: true,
          showActionToggle: true,
          actions: [
            {
              type: "success",
              title: "Change Tenant Status",
              description: "Delinquent",
              linkText: 'View Document',
              auditTrail: true,
              auditTrailTooltipName: 'Severus Snape',
              auditTrailTooltipTime: 'Sept 20, 2023 @ 4:30pm'
            },
          ],
        },
        {
          stageType: "past",
          daySection: {
            title: "Day 6",
            descriptionOne: "Jun 26, 2022",
          },
          nameSection: {
            title: "Gate Lockout and Late Fee",
            descriptionOne:
              "Deny Access to the Tenant and Charge the legal Late Fee",
            descriptionTwo: "line 2 description",
          },
          expandAction: true,
          showActionToggle: true,
          actions: [
            {
              type: "success",
              title: "Deny Access",
              description: "Delinquent",
            },
            {
              type: "success",
              title: "Add Fee",
              description: "Late Fee ($20)",
            },
          ],
        },
        {
          stageType: "error",
          daySection: {
            title: "Day 26",
            descriptionOne: "Jul 22, 2022",
          },
          nameSection: {
            title: "Generate Lien letter",
            descriptionOne:
              "Generate a Lien letter for the tenant and change status to Active Lien",
            descriptionTwo: "line 2 description",
          },
          expandAction: true,
          showActionToggle: true,
          actions: [
            {
              type: "success",
              title: "Change Tenant Status",
              description: "Active Lien",
              auditTrail: true,
              auditTrailTooltipName: 'Severus Snape',
              auditTrailTooltipTime: 'Sept 20, 2023 @ 4:30pm'
            },
            {
              type: "error",
              title: "Generate Document",
              description: "Notice of Lien Sale",
              linkText: 'View Document',
              auditTrail: true,
              auditTrailTooltipName: 'Severus Snape',
              auditTrailTooltipTime: 'Sept 20, 2023 @ 4:30pm'
            },
          ],
        },
        {
          stageType: "error",
          daySection: {
            title: "Day 31",
            descriptionOne: "Jul 27, 2022",
          },
          nameSection: {
            title: "Inventory Property / Cut Lock",
            descriptionOne: "Create a task for cust lock and inventory",
          },
          expandAction: true,
          showActionToggle: true,
          actions: [
            {
              type: "error",
              title: "Create Task",
              description: "Cut Lock and Inventory",
            },
          ],
        },
        {
          stageType: "past",
          daySection: {
            title: "Day 32",
            descriptionOne: "Jul 28, 2022",
          },
          nameSection: {
            title: "Lock Cut & Inventory",
            descriptionOne:
              "This is done in order to take inventory of space contents for the purpose of auction",
            descriptionTwo:
              "advertisements, as required by state self storage lien laws.",
          },
          expandAction: true,
          showActionToggle: true,
          actions: [
            {
              type: "success",
              title: "Lock Cut & Inventory",
            },
          ],
        },
        {
          stageType: "today",
          daySection: {
            title: "",
            descriptionOne: "Aug 06, 2022",
          },
          nameSection: {
            title: "",
            descriptionOne: "",
          },
          expandAction: false,
          showActionToggle: false,
          actions: [],
        },
        {
          stageType: "current",
          daySection: {
            title: "Day 61",
            descriptionOne: "Sep 06, 2022",
          },
          nameSection: {
            title: "Schedule Auction",
            descriptionOne:
              "Initiate the scheduling of an auction for this tenant",
          },
          expandAction: true,
          showActionToggle: true,
          today: false,
          actions: [
            {
              title: "Schedule Auction",
            },
            {
              title: "Task",
              description: "Task to tow vehicles and boats",
            },
          ],
        },
        {
          stageType: "future",
          daySection: {
            title: "Day 70",
            descriptionOne: "Sep 08, 2022",
          },
          nameSection: {
            title: "Eviction",
            descriptionOne:
              "Initiate the scheduling of an auction for this tenant",
          },
          expandAction: true,
          showActionToggle: true,
          actions: [
            {
              title: "Notify tenant",
            },
          ],
        },
      ],
      stagesEditable: [
        {
          stageType: "default",
          daySection: {
            title: "Day 1",
            descriptionOne: "Jun 20, 2022",
          },
          nameSection: {
            title: "Tenant Rent Assessment",
            descriptionOne: "This is the day the tenant's rent is due",
          },
        },
        {
          stageType: "default",
          daySection: {
            title: "Day 2",
            descriptionOne: "Jun 21, 2022",
          },
          nameSection: {
            title: "Tenant becomes Delinquent",
            descriptionOne: "Description",
            descriptionTwo: "line 2 description",
          },
          expandAction: true,
          showActionToggle: true,
          actions: [
            {
              title: "Change Tenant Status",
              description: "Delinquent",
              linkText: 'View Document',
              auditTrail: true,
              auditTrailTooltipName: 'Severus Snape',
              auditTrailTooltipTime: 'Sept 20, 2023 @ 4:30pm'
            },
          ],
        },
        {
          stageType: "default",
          daySection: {
            title: "Day 6",
            descriptionOne: "Jun 26, 2022",
          },
          nameSection: {
            title: "Gate Lockout and Late Fee",
            descriptionOne:
              "Deny Access to the Tenant and Charge the legal Late Fee",
            descriptionTwo: "line 2 description",
          },
          expandAction: true,
          showActionToggle: true,
          actions: [
            {
              title: "Deny Access",
              description: "Delinquent",
            },
            {
              title: "Add Fee",
              description: "Late Fee ($20)",
            },
          ],
        },
        {
          stageType: "default",
          daySection: {
            title: "Day 26",
            descriptionOne: "Jul 22, 2022",
          },
          nameSection: {
            title: "Generate Lien letter",
            descriptionOne:
              "Generate a Lien letter for the tenant and change status to Active Lien",
            descriptionTwo: "line 2 description",
          },
          expandAction: true,
          showActionToggle: true,
          actions: [
            {
              title: "Change Tenant Status",
              description: "Active Lien",
              auditTrail: true,
              auditTrailTooltipName: 'Severus Snape',
              auditTrailTooltipTime: 'Sept 20, 2023 @ 4:30pm'
            },
            {
              title: "Generate Document",
              description: "Notice of Lien Sale",
              linkText: 'View Document',
              auditTrail: true,
              auditTrailTooltipName: 'Severus Snape',
              auditTrailTooltipTime: 'Sept 20, 2023 @ 4:30pm'
            },
          ],
        },
        {
          stageType: "default",
          daySection: {
            title: "Day 31",
            descriptionOne: "Jul 27, 2022",
          },
          nameSection: {
            title: "Inventory Property / Cut Lock",
            descriptionOne: "Create a task for cust lock and inventory",
          },
          expandAction: true,
          showActionToggle: true,
          actions: [
            {
              title: "Create Task",
              description: "Cut Lock and Inventory",
            },
          ],
        },
        {
          stageType: "default",
          daySection: {
            title: "Day 32",
            descriptionOne: "Jul 28, 2022",
          },
          nameSection: {
            title: "Lock Cut & Inventory",
            descriptionOne:
              "This is done in order to take inventory of space contents for the purpose of auction",
            descriptionTwo:
              "advertisements, as required by state self storage lien laws.",
          },
          expandAction: true,
          showActionToggle: true,
          actions: [
            {
              title: "Lock Cut & Inventory",
            },
          ],
        },
        {
          stageType: "default",
          daySection: {
            title: "Day 61",
            descriptionOne: "Sep 06, 2022",
          },
          nameSection: {
            title: "Schedule Auction",
            descriptionOne:
              "Initiate the scheduling of an auction for this tenant",
          },
          expandAction: true,
          showActionToggle: true,
          today: false,
          actions: [
            {
              title: "Schedule Auction",
            },
            {
              title: "Task",
              description: "Task to tow vehicles and boats",
            },
          ],
        },
        {
          stageType: "default",
          daySection: {
            title: "Day 70",
            descriptionOne: "Sep 08, 2022",
          },
          nameSection: {
            title: "Eviction",
            descriptionOne:
              "Initiate the scheduling of an auction for this tenant",
          },
          expandAction: true,
          showActionToggle: true,
          actions: [
            {
              title: "Notify tenant",
            },
          ],
        },
      ],
    };
  },
  created() {
    this.events = [
      this.past,
      this.error,
      this.today,
      this.current,
      this.future,
      this.default
    ];
    this.events2 = this.deepClone(this.events);
  },
  computed: {
    stageList() {
      return this.events.map((event, index) => ({
        text: "Stage " + (index + 1),
        value: index,
      }));
    },
    eventsObj() {
      let events = this.deepClone(this.events)
      return events.map((event, index) => {
        delete event.actions
        // delete event.actionCount
        delete event.customIcon
        delete event.iconColor
        delete event.lineColor
        delete event.expandAction
        if(this.showMainSlot) {
          delete event.today
          delete event.descriptionFontColor
          delete event.daySection
          delete event.nameSection
          this.removeActionProps(event);
        }
        if(this.showFirstColSlot && this.showSecondColSlot) {
          delete event.today
          delete event.daySection
          delete event.nameSection
          delete event.descriptionFontColor
          this.removeActionProps(event);
        }
        if(this.showFirstColSlot && !this.showSecondColSlot && this.showActionSlot) {
          delete event.today
          delete event.daySection
        }
        if(this.showFirstColSlot && !this.showSecondColSlot && !this.showActionSlot) {
          delete event.today
          delete event.daySection
          this.removeActionProps(event);
        }
        if(!this.showFirstColSlot && this.showSecondColSlot) {
          delete event.nameSection
          this.removeActionProps(event);
        }
        if(!this.showFirstColSlot && !this.showSecondColSlot && !this.showActionSlot) {
          this.removeActionProps(event);
        }
        
        return event
      })
    }
  },
  methods: {
    changeStageType(stageType, selectedStage) {
      let eventsCopy = this.deepClone(this.events2);
      let selectedStageTypeData = this.events2.find(event => event.stageType === stageType)
      let stageTypeDefaultData = this.deepClone(selectedStageTypeData);      
      eventsCopy[selectedStage] = { ...stageTypeDefaultData, stageType: stageType }
      this.events = eventsCopy;
    },
    deepClone(value) {
      return JSON.parse(JSON.stringify(value));
    },
    hasFieldvalue(value) {
      return value || value == "";
    },
    addStage() {
      this.events.push(this.deepClone(this[this.newStageType]));
      this.newStageType = null;
    },
    copyCode(elementId) {
      let content = document.getElementById(elementId).innerText;
      let textareaContent = document.createElement('textarea');
      textareaContent.value = content;
      document.body.appendChild(textareaContent);
      textareaContent.select();
      document.execCommand('copy');
      document.body.removeChild(textareaContent);
      this.successToggle = true
    },
    removeActionProps(event) {
      delete event.actionToggleColor
      delete event.expandAction
      delete event.showActionToggle
      delete event.actionBorder
      delete event.actionWrapperClass
      delete event.actionLabel
    },
    handleClick(e, source){
        this.successErrorDescription = source + ' Click Event:';
        this.successErrorList = [
          e
        ]
        this.successError = true;
    },
  },
  watch: {},
};
</script>

<style lang="scss">
.timeline-documentation-page{
  .slot-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    border: 1px solid black;
    justify-content: center;
    align-items: center;
    padding: 5px;
    min-height: 100px;
  }
  .copy-icon {
    cursor: pointer;
  }
}
</style>
